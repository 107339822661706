import React from "react";
import { Helmet } from "react-helmet";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import Project from "../templates/project";
import Discourse from "../templates/discourse";

const NotFoundPage = () => (
  <>
    <Helmet title="404 – PUP Architects" />
    <h1>Page Not Found</h1>
  </>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    project: Project,
    discourse: Discourse,
  },
});
